<template>
  <div class="close-btn-wrap">
    <button class="btn" @click="close"><icon-close /></button>
    <div ref="lineRef" class="line">
      <div class="trigger" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useModal from '@/composables/use-modal';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import IconClose from './assets/close-green.svg';

const props = defineProps<{ name: string }>();
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const modal = useModal();

const lineRef = ref<HTMLElement>(null as unknown as HTMLElement);
let hammer: any;

const close = () => {
  emit('close');
  modal.hide(props.name);
};

onMounted(() => {
  import('hammerjs').then(({ default: Hammer }) => {
    hammer = new Hammer(lineRef.value as HTMLElement);
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });

    hammer.on('swipe', (e: { deltaY: number }) => {
      if (e.deltaY > 0) {
        close();
      }
    });
  });
});

onBeforeUnmount(() => {
  hammer.destroy();
});
</script>

<style lang="scss" scoped>
.close-btn-wrap {
  width: 100%;
  position: relative;
  margin-top: -16px;
  margin-bottom: 16px;

  @include media-min-md {
    margin: 0;
    height: 0;
    line-height: 0;
  }
}

.btn {
  position: absolute;
  top: -8px;
  right: -8px;
  height: 24px;
  width: 24px;
  display: none;

  @include media-min-md {
    display: block;
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;

  @include media-min-md {
    display: none;
  }
}

.trigger {
  width: 64px;
  height: 4px;
  border-radius: 100px;
  background-color: $Bottom_20;
}
</style>
