import { useStore } from 'vuex';
import type { AdvertAgreement } from '@/types/dto/advertAgreement';
import useCabinetApi from '@/composables/use-cabinet-api';
import { captureClientException } from '@/utils/handleErrors/handleErrors';

export default function useAdvertAgreement() {
  const api = useCabinetApi();
  const store = useStore();

  const loadStatus = async (): Promise<AdvertAgreement | undefined> => {
    try {
      const data: AdvertAgreement = await api.apiClient.getUserAdvertAgreement();
      store.commit('cabinet/setAgreement', data);

      return data;
    } catch (error) {
      captureClientException({ error, place: 'AdvertAgreement' });
    }
  };

  const save = async (agreed: boolean) => {
    store.commit('cabinet/setAgreement', { agreed });

    try {
      await api.apiClient.saveUserAdvertAgreement(agreed);
    } catch (error) {
      captureClientException({ error, place: 'AdvertAgreement' });
    }
  };

  return {
    loadStatus,
    save,
  };
}
