import type { LkWebsiteId } from '@/env';

export const CABINET_CALCULATE = '/cabinet/calculate/';

export const DELIVERY_TYPES = Object.freeze({
  OFFICE: 'OFFICE',
  HOME: 'HOME',
  POSTAMAT: 'POSTAMAT',
});

export const PARCEL_TARIFF_ID = 333;
// потому что неизвестно какие будут айди для других сред (тут указыны айди для препрода)
export const PROFI_TARIFF_OFFICE_OFFICE_ID = import.meta.env.VITE_PROFI_TARIFF_OFFICE_OFFICE_ID
  ? Number(import.meta.env.VITE_PROFI_TARIFF_OFFICE_OFFICE_ID)
  : 2245;
export const PROFI_TARIFF_OFFICE_POSTAMAT_ID = import.meta.env.VITE_PROFI_TARIFF_OFFICE_POSTAMAT_ID
  ? Number(import.meta.env.VITE_PROFI_TARIFF_OFFICE_POSTAMAT_ID)
  : 2248;

export const DEFAULT_RUB_VALUE = {
  id: 1,
  name: 'Российский рубль',
  shortName: 'RUB',
  sign: '₽',
  default: true,
};

export const DEFAULT_KZT_VALUE = {
  id: 2,
  name: 'Теңге',
  shortName: 'KZT',
  sign: '₸',
  default: true,
};

export const DEFAULT_BYN_VALUE = {
  id: 7,
  name: 'Белорусский рубль',
  shortName: 'BYN',
  sign: 'Br',
  default: false,
};

export const DEFAULT_USD_VALUE = {
  id: 3,
  name: 'Доллар США',
  shortName: 'USD',
  sign: '$',
  default: false,
};

export const DEFAULT_AM_VALUE = {
  id: 10,
  name: 'Армянский драм',
  shortName: 'AMD',
  sign: '֏',
  default: false,
};

export const DEFAULT_KG_VALUE = {
  id: 9,
  name: 'Киргизский сом',
  shortName: 'KGS',
  sign: 'с',
  default: false,
};

export const DEFAULT_GE_VALUE = {
  id: 19,
  name: 'Лари',
  shortName: 'GEL',
  sign: '₾',
  default: false,
};

export const DEFAULT_UZ_VALUE = {
  id: 15,
  name: 'Сум',
  shortName: 'UZS',
  sign: 'Soʻm',
  default: false,
};

export const DEFAULT_AZ_VALUE = {
  id: 18,
  name: 'Манат',
  shortName: 'AZN',
  sign: '₼',
  default: false,
};

export const DEFAULT_CURRENCY_BY_WEBSITE_ID: Record<LkWebsiteId | 'us', typeof DEFAULT_RUB_VALUE> =
  {
    ru: DEFAULT_RUB_VALUE,
    kz: DEFAULT_KZT_VALUE,
    by: DEFAULT_BYN_VALUE,
    us: DEFAULT_USD_VALUE,
    kg: DEFAULT_KG_VALUE,
    am: DEFAULT_AM_VALUE,
    ge: DEFAULT_GE_VALUE,
    uz: DEFAULT_UZ_VALUE,
    az: DEFAULT_AZ_VALUE,
  };

export const POSTAMAT = 'postomat';

export const COUNTRIES: Readonly<Record<Uppercase<LkWebsiteId>, number>> = Object.freeze({
  RU: 1,
  KZ: 48,
  AM: 41,
  KG: 15,
  BY: 42,
  GE: 58,
  UZ: 46,
  AZ: 40,
});

export const COUNTRIES_ALIASES: Readonly<{ [key: number]: LkWebsiteId }> = {
  [COUNTRIES.RU]: 'ru',
  [COUNTRIES.KZ]: 'kz',
  [COUNTRIES.AM]: 'am',
  [COUNTRIES.KG]: 'kg',
  [COUNTRIES.BY]: 'by',
  [COUNTRIES.GE]: 'ge',
  [COUNTRIES.UZ]: 'uz',
  [COUNTRIES.AZ]: 'az',
};

export const COUNTRIES_TO: number[] = [
  COUNTRIES.RU,
  COUNTRIES.KZ,
  COUNTRIES.AM,
  COUNTRIES.KG,
  COUNTRIES.BY,
  COUNTRIES.UZ,
  COUNTRIES.GE,
  COUNTRIES.AZ,
];

export const COUNTRIES_FROM: number[] = [
  COUNTRIES.RU,
  COUNTRIES.KZ,
  COUNTRIES.AM,
  COUNTRIES.KG,
  COUNTRIES.BY,
  COUNTRIES.UZ,
  COUNTRIES.GE,
  COUNTRIES.AZ,
];

export const BOX_AVAILABLE_SIZES = ['XS', 'S', 'L', 'M'];

// перечесление id разрешенных от постамата услуг, перечень вот здесь https://docs.google.com/spreadsheets/d/1cNdxuy0q6wJWqGesBcWJdaZxLRoFZ7t4wXlTtKzGHvA/edit#gid=628202538
export const SUPER_EXPRESS_TARIFF_IDS = [3, 57, 58, 59, 60, 61];

export const CLIENT_TYPE_SENDER = 'sender';
export const CLIENT_TYPE_RECEIVER = 'receiver';
